import type { ComputedRef } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import type { IUseListLimitBreakpoints } from "./use-list";

const customBreakpoints = {
  "3xl": 2560,
  "4xl": 3440,
  "5xl": 3840,
};

const breakpoints = useBreakpoints({ ...breakpointsTailwind, ...customBreakpoints });
const sm = breakpoints.smaller("sm");
const md = breakpoints.between("sm", "md");
const lg = breakpoints.between("md", "lg");
const xl = breakpoints.between("lg", "xl");
const xl2 = breakpoints.between("xl", "2xl");
const xl3 = breakpoints.between("2xl", "3xl");
const xl4 = breakpoints.between("3xl", "4xl");
const xl5 = breakpoints.between("4xl", "5xl");
const xl6 = breakpoints["5xl"];

const defaultBreakpoints = { sm: 3, md: 4, lg: 4, xl: 6, xl2: 8, xl3: 10, xl4: 12, xl5: 16, xl6: 20 };

export default function useBreakpointsLimit(bp: IUseListLimitBreakpoints = defaultBreakpoints): ComputedRef<number> {
  return computed(() => {
    if (sm.value && "sm" in bp)
      return bp.sm;
    if (md.value && "md" in bp)
      return bp.md;
    if (lg.value && "lg" in bp)
      return bp.lg;
    if (xl.value && "xl" in bp)
      return bp.xl;
    if (xl2.value && "xl2" in bp)
      return bp.xl2;
    if (xl3.value && "xl3" in bp)
      return bp.xl3;
    if (xl4.value && "xl4" in bp)
      return bp.xl4;
    if (xl5.value && "xl5" in bp)
      return bp.xl5;
    if (xl6.value && "xl6" in bp)
      return bp.xl6;
    return 10;
  });
}
