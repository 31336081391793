import { formatIdentityItem, formatPaginatedResponseItems, formatRequestOptions } from "../format";
import useRequest from "~/hooks/use-request";
import EventsAPI from "~/services/events";

const SubCategoriesAPI = {
  load(options: IRequestPaginatedOptions & { identityId?: string }): Promise<IPaginatedResponse<IModelIdentityItem>> {
    const { request } = useRequest();
    const params = formatRequestOptions(options);

    if (options.identityId) {
      params.sort = `media_parent_order.${options.identityId}`;
      // @ts-expect-error FIXME:
      params.filters = { identityId: options.identityId, media_type: "identity_item" };
    }

    return request
      .post<IPaginatedResponse<IResponseIdentity>>("/api/identity/list", { body: params })
      .then(response => formatPaginatedResponseItems(response, formatIdentityItem));
  },

  loadAllSubCategory(): Promise<IPaginatedResponse<IModelIdentityItem>> {
    const { request } = useRequest();
    const params = { filters: { media_type: "identity_item" }, page: 0, size: 100 };

    return request
      .post<IPaginatedResponse<IResponseIdentity>>("/api/identity/list", { body: params })
      .then(response => formatPaginatedResponseItems(response, formatIdentityItem));
  },

  async loadById(subCategoryId: string, options?: { mainCategoryId: string; loadTotals: boolean }): Promise<IModelIdentityItem> {
    const { request } = useRequest();

    return await request
      .get<IContentResponse<IResponseIdentityItem>>(`/api/identity/get_by_id/id/${subCategoryId}`)
      .then(async (res) => {
        const subCategory = formatIdentityItem(res.content);

        if (options?.loadTotals) {
          const params = { countOnly: true, subCategoryId, mainCategoryId: options.mainCategoryId };

          await Promise.all([
            EventsAPI.load({ ...params, type: "archived" }), // latest events
            EventsAPI.load({ ...params, type: "upcoming" }), // upcoming events
            EventsAPI.load({ ...params, type: "live" }), // live events
          ]).then(([res1, res2, res3]) => {
            subCategory.totalVodEventsNumber = res1.content.totalCount || 0;
            subCategory.totalUpcomingEventsNumber = res2.content.totalCount || 0;
            subCategory.totalLiveEventsNumber = res3.content.totalCount || 0;
            subCategory.totalEventsNumber = subCategory.totalVodEventsNumber + subCategory.totalUpcomingEventsNumber + subCategory.totalLiveEventsNumber;
          });
        }

        return subCategory;
      });
  },
};

export default SubCategoriesAPI;
