import { formatIdentity, formatPaginatedResponseItems, formatRequestOptions } from "../format";
import SubCategoriesAPI from "./sub";
import useRequest from "~/hooks/use-request";
import EventsAPI from "~/services/events";
import { ORDER, SORT } from "~/constants";

const CategoriesAPI = {
  subCategories: SubCategoriesAPI,

  load(options?: IRequestPaginatedOptions): Promise<IPaginatedResponse<IModelIdentity>> {
    const { request } = useRequest();
    const generalParams = formatRequestOptions(options);
    const params = { ...generalParams, filters: { media_type: "identity", archived: options?.archived } };

    return request
      .post<IPaginatedResponse<IResponseIdentity>>("/api/identity/list", { body: params })
      .then(response => formatPaginatedResponseItems(response, formatIdentity));
  },

  loadById(id: string, options?: { loadTotals: boolean }): Promise<IModelIdentity> {
    const { request } = useRequest();

    return request
      .get<IContentResponse<IResponseIdentity>>(`/api/identity/get_by_id/id/${id}`)
      .then(async (response) => {
        const category = formatIdentity(response.content);

        if (options?.loadTotals) {
          const params = { countOnly: true, mainCategoryId: id };

          await Promise.all([
            EventsAPI.load({ ...params, type: "archived" }), // latest events
            EventsAPI.load({ ...params, type: "upcoming" }), // upcoming events
            EventsAPI.load({ ...params, type: "live" }), // live events
          ]).then(([res1, res2, res3]) => {
            category.totalVodEventsNumber = res1.content.totalCount || 0;
            category.totalUpcomingEventsNumber = res2.content.totalCount || 0;
            category.totalLiveEventsNumber = res3.content.totalCount || 0;
            category.totalEventsNumber = category.totalVodEventsNumber + category.totalUpcomingEventsNumber + category.totalLiveEventsNumber;
          });
        }
        return category;
      });
  },

  async loadWithSubcategories() {
    const { content: { entries: categories } } = await CategoriesAPI.load({ page: 0, pageSize: 100, order: ORDER.asc, sort: SORT.order });
    const { content: { entries: subcategories } } = await SubCategoriesAPI.loadAllSubCategory();

    return categories.map((category) => {
      return {
        ...category,
        subCategories: subcategories.filter(subcategory => subcategory.mainCategories.includes(category.id)),
      };
    });
  },
};

export default CategoriesAPI;
